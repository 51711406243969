import types from "../actions/action-types";
const ACTION = types.OPTIMIZE;
const DEFAULT_STATE = {
  isLoading: false,
  options: {},
  teams: [],
  isCaptainMode: false
}
export default (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case `${ACTION}_LOADING`:
      return {
        isCaptainMode: false,
        isLoading: true,
        options: action.updatePayload
      }
    case `${ACTION}_REJECTED`:
      return {
        ...state,
        isLoading: false,
        loginResponse: action.updatePayload,
      };
    case `${ACTION}_SUCCESS`:
      const isCaptainMode = action.updatePayload.res.isCaptainMode;
      const site = action.updatePayload.res.site;
      const playerCounts = action.updatePayload.res.playerCounts;
      const allTeams = getTeams(action.updatePayload.res.lineups || [], isCaptainMode, site);
      if(action.updatePayload.res.lineups){
        const notFeasible = action.updatePayload.res.lineups.some(lineup=>lineup.feasible===false);
        if(notFeasible){
          return {
            ...state,
            isCaptainMode,
            error: 'No feasible lineup(s) are possible with the current selection.',
            isLoading: false
          }
        }
      } else if(action.updatePayload.isError){
        return {
          ...state,
          error: action.updatePayload.res.msg,
          isLoading:false
        }
      }

      return {
        ...state,
        isCaptainMode,
        error: false,
        options: {...state.options, site},
        isLoading: false,
        ...action.updatePayload,
        allTeams,
        playerCounts,
        isWaiting:false,
        noHistory:allTeams.length === 0,
      };
    case `${ACTION}_WAITING`:
      return {
        noHistory: false,
        isLoading: false,
        options: state.options,
        ...action.updatePayload.res,
      };

    default:
      return state;
  }
};

function getTeams(allTeams, isCaptainMode, site) {
  return allTeams.map(team => {
    const total = team.result;
    let salary = 0;
    const counts = { QB: 1, RB: 2, WR: 3, TE: 1, DST: 1, FLEX: 1 };
    const players = { QB: [], RB: [], WR: [], TE: [], DST: [], FLEX: [] };

    if(isCaptainMode){
      const sortedTeam = team.players.sort((a, b) => (b.projection + (b.isCaptain?1000:0)) - (a.projection+(a.isCaptain?1000:0)))
      if(site === 'DraftKings')
        sortedTeam.forEach(player=>player.siteId = player.captainId);
      return  {
        total,
        players: sortedTeam,
        salary: sortedTeam.reduce((cum, curr)=>curr.cost+cum,0)
      }
    }
    const sortedTeam = team.players.sort((a, b) => b.projection - a.projection)
    sortedTeam.forEach(player => {
      salary += player.cost;
      if (players[player.position].length === counts[player.position]) {
        players.FLEX.push(player);
      } else {
        players[player.position].push(player)
      }
    })
    return {
      total, salary, players: players.QB.concat(players.RB, players.WR, players.TE, players.FLEX, players.DST)
    }
  })
}