import axiosCall from "./index";
import types from "./action-types";
import URL from "../../config/routes";
import app from "../../firebase/app";
import { AuthWatcher } from "../../services/auth";


/**
 * Action to sign in the user.
 * @param {{email:string,password:string}} data
 */
export const login = (data) => {
	const path = URL.LOGIN;
	const responseType = types.LOGIN;
	return (dispatch) => {
		dispatch({
			type: `${responseType}_LOADING`
		})
		app.auth()
			.signInWithEmailAndPassword(data.email, data.password)
			.then(credentials => Promise.all([credentials, credentials.user.getIdToken()]))
			.then(([credentials, jwt]) => {
				return axiosCall('post', path, responseType, { jwt })(dispatch);
			}).then(token => AuthWatcher.notify({ email: data.email, token: token.res.jwt }))
			.catch(err => {
				//Handle the firebase authentication error.
				if (err?.code?.startsWith('auth')) {
					dispatch({
						type: `${responseType}_REJECTED`,
						updatePayload: {isError:true, res:{msg:'Invalid user name or password.'}},
					});
				}
			})

	}
};
