import { makeStyles, Typography } from "@material-ui/core";
import { grey } from "@material-ui/core/colors";
import axios from "axios";
import React, { useEffect, useState } from "react";
import routes from "../../config/routes";
import { setAuthToken } from "../../services/auth";

const useStyles = makeStyles(theme => ({
    footer: {
        color: grey[700],
        fontSize: '0.75rem',
        marginLeft: theme.spacing(2)
    }
}))



function Footer(props = {}) {
    const [lastUpdated,setLastUpdated]=useState(null);
    useEffect(()=>{
        setAuthToken()
        .then(_ => axios.get(routes.GET_LAST_UPDATED))
            .then(({data})=>data.res.lastUpdated)
            .then(lastUpdated=>setLastUpdated(lastUpdated))
            .catch(err=>console.error(err));
    },[])
    const classes = useStyles();
    return (
        <footer {...props} style={{width:'250px'}}>
            {lastUpdated &&
            <Typography variant='subtitle2' className={classes.footer} >
                Last Updated: {lastUpdated}
            </Typography>
            }
        </footer>
    )
}

export default Footer;