import React, { useCallback } from 'react';
import { AppBar, Hidden, Button, Drawer, Toolbar, IconButton, Menu, MenuItem } from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import NavBarLink from './navBarLink';
import "./style.css";
import logo from "../../assets/img/dfs_nav_logo.png";
import { useState } from 'react';
import { useMemo } from 'react';
import DrawerLink from './drawerLink';
import { onLogout } from '../../services/auth';
import { AccountCircle } from '@material-ui/icons';


// tab list
const tabs = [
    { title: "Optimize", linkTo: "/optimizer/setup" },
    { title: "Player Settings", linkTo: "/optimizer/player-settings" },
    { title: "All teams", linkTo: "/optimizer/all-teams" },
];


/**
 * Navigation bar
 * @returns {React.ReactNode}
 */
function NavBar(props) {
    const activeTabIndex = useMemo(() => tabs.findIndex(tab => tab.linkTo === props.location.pathname), [props.location.pathname]);
    const [isDrawerOpen, setDrawerState] = useState(false);

    const logout = useCallback(() => {
        onLogout();
    },[])

    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = useCallback(() => {
        setAnchorEl(null);
    },[]);
    const handleFeedback = useCallback(() => {
        window.open('https://support.fantasypoints.com//hc/en-us/requests/new?ticket_form_id=360001131571','_blank');
        handleClose();
    }, [handleClose])

    return (
        <div>
            <AppBar className="app-bar" position='relative'>
                <Toolbar>
                    <img src={logo} height='60' alt="logo" />
                    <span className="spacing"/>
                    <Hidden smDown>
                        {
                            tabs.map((tab, index) => (
                            <NavBarLink
                                linkTo={tab.linkTo}
                                active={index === activeTabIndex}
                                key={index}
                                label={tab.title} />
                            )).concat([
                                <React.Fragment key='logout-button'>
                                    <IconButton onClick={handleClick}>
                                        <AccountCircle color='primary' />
                                    </IconButton>
                                    <Menu id='user-menu'
                                        key='logoutMenu'
                                        anchorEl={anchorEl}
                                        keepMounted
                                        open={Boolean(anchorEl)}
                                        onClose={handleClose}
                                    >
                                        <MenuItem onClick={handleFeedback}>Feedback/Support</MenuItem>
                                        <MenuItem onClick={logout}>Log Out</MenuItem>
                                    </Menu>

                                </React.Fragment>
                                ])
                        }

                    </Hidden>
                    <Hidden mdUp>
                        <Button onClick={(e) => {setDrawerState(true);}}>
                            <MenuIcon/>
                        </Button>
                    </Hidden>
                </Toolbar>
            </AppBar>
            <Drawer
                anchor="left"
                open={isDrawerOpen}
                onClose={e => setDrawerState(false)}>

                <div role="presentation">
                    <img src={logo} height={64} alt="logo" className="drawer-logo" />

                    <div className="list">
                        {
                            tabs.map((tab, index) => (
                                <div key={index}>
                                    <DrawerLink
                                        linkTo={tab.linkTo}
                                        active={index === activeTabIndex}
                                        label={tab.title}
                                    />
                                    <br />
                                </div>
                            )).concat([
                                <div key='support'>
                                    <DrawerLink
                                    onClick={handleFeedback}
                                    linkTo='#'
                                    active={false}
                                    label='Support'
                                />
                            </div>,
                                <div key='logout'>
                                    <DrawerLink
                                        onClick={logout}
                                        linkTo='#'
                                        active={false}
                                        label='Logout'
                                    />
                                    <br />
                                </div>
                            ])
                        }
                    </div>
                </div>
            </Drawer>
        </div>
    );
}

export default NavBar;
