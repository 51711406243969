import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
    TextField, makeStyles, Grid, FormLabel, Slider, Dialog,
    DialogTitle, Container, DialogActions, DialogContent,
    FormControlLabel, Checkbox, Typography, InputAdornment
} from '@material-ui/core';
import { FantasyButton } from '../fatnasyButton';
import { useSnackbar } from 'notistack';

const useStyles = makeStyles(theme => ({
    root: {
        borderRadius: theme.spacing(2),
        padding: theme.spacing(1)
    },
    checkboxLabel: {
        fontSize: '0.75rem'
    },
    input: {
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
    }
}));

const FlexExposureDialog = (props) => {
    const {
        initialValues,
        onDone,
        onCancel,
        isOpen,
        title,
        stateKey,
        min, max,
        flexRB,
        flexTE,
        flexWR,
        ...rest
    } = props;

    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();
    const [values, setValues] = useState(initialValues);

    useEffect(() => {
        if (isOpen)
            setValues(initialValues);
    }, [isOpen]);

    let positions = useMemo(() =>
        ({ 'RB': flexRB, 'WR': flexWR, 'TE': flexTE })
        , [flexRB, flexWR, flexTE]);

    const inputHandlers = useMemo(() => {
        const getConstraintedValue = (value, min, max) => {
            value = +value || value;
            if (+value) {
                value = Math.min(Math.max(min, value), max);
            }
            return value;
        }
        const onSliderChanged = (pos, min, max) => (e, value) => {
            setValues(values => ({ ...values, [pos]: getConstraintedValue(value, min, max) }))
        }
        return {
            input: (e) => {
                const { name, value } = e.target;
                const minValue = name === 'GLOBAL' ? min : 0;
                setValues(values => ({ ...values, [name]: getConstraintedValue(value, minValue, 100) }))
            },
            sliderRB: onSliderChanged('RB', 0, 100),
            sliderWR: onSliderChanged('WR', 0, 100),
            sliderTE: onSliderChanged('TE', 0, 100),
            sliderGlobal: onSliderChanged('GLOBAL', min, max),
            checkbox: e => {
                const { name, checked } = e.target;
                setValues(values => ({ ...values, [name]: checked }));
            }
        }
    }, [setValues, min, max]);

    const onSubmit = useCallback(() => {
        let error;
        const activePositions = Object.keys(positions).filter(pos=>positions[pos]);
        const posTotal = activePositions.reduce((cum,pos)=>{
            const value =  values.custom ? +values[pos]:values.GLOBAL;
            return cum+value;
        },0);

        if (posTotal < 100) {
            error = values.custom ? 'The sum of values of all positions should be greater than or equal to 100%':
                `Minimum exposure for all positions must be at least ${min}%.`;
            enqueueSnackbar(error, {
                variant: 'error',
                anchorOrigin: {
                    horizontal: 'right',
                    vertical: 'bottom'
                }
            });
        } else {
            onDone({
                [stateKey]: values
            });
            onCancel();
        }

    }, [values, onDone, onCancel, positions]);



    return (
        <Dialog
            open={isOpen}
            fullWidth maxWidth='xs'
            {...rest}
            classes={{ paper: classes.root }}>
            <DialogTitle >{title}</DialogTitle>
            <DialogContent  >

                <Container
                    fixed
                    maxWidth="md"
                    disableGutters
                    className={classes.root}
                >
                    <Grid container alignItems='center'>
                        <Grid item xs={8} >
                            <span>
                                <FormLabel className={classes.checkboxLabel} id="discrete-slider-restrict"> All Positions </FormLabel>
                            </span>
                            <Slider defaultValue={1}
                                name={`GLOBAL`}
                                aria-labelledby="discrete-slider-restrict"

                                min={min}
                                max={max}
                                value={values.GLOBAL}
                                disabled={values.custom}
                                valueLabelDisplay="auto"
                                draggable={true}
                                style={{ width: '95%', marginLeft: '2.5%' }}
                                color="secondary"
                                onChange={inputHandlers.sliderGlobal} />
                        </Grid>
                        <Grid item xs={4} className={classes.input}>
                            <TextField
                                name={`GLOBAL`}
                                disabled={values.custom}
                                label='All Positions'
                                type='number'
                                variant='outlined'
                                size='small'
                                value={values.GLOBAL}
                                onChange={inputHandlers.input}
                                InputProps={{
                                    endAdornment: <InputAdornment position="end"><Typography>%</Typography></InputAdornment>,
                                }}
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={values.custom}
                                    onChange={inputHandlers.checkbox}
                                    name="custom"
                                    color="primary"
                                />
                            }
                            labelPlacement='end'
                            label="Customize by Position"
                        />
                    </Grid>
                    {Object.keys(positions).map(pos => (
                        <Grid container key={pos} alignItems='center'>
                            <Grid item xs={8} >
                                <span>
                                    <FormLabel className={classes.checkboxLabel} id="discrete-slider-restrict"> {pos} </FormLabel>
                                </span>
                                <Slider defaultValue={1}
                                    name={pos}
                                    aria-labelledby="discrete-slider-restrict"
                                    step={1}
                                    disabled={!values.custom || !positions[pos]}
                                    min={0}
                                    max={max}
                                    value={values[pos]}
                                    valueLabelDisplay="auto"
                                    draggable={true}
                                    style={{ width: '95%', marginLeft: '2.5%' }}
                                    color="secondary"
                                    onChange={inputHandlers[`slider${pos}`]} />
                            </Grid>
                            <Grid item xs={4} className={classes.input}>
                                <TextField
                                    name={pos}
                                    label={pos}
                                    type='number'
                                    disabled={!values.custom || !positions[pos]}
                                    variant='outlined'
                                    size='small'
                                    value={values[pos]}
                                    onChange={inputHandlers.input}
                                    InputProps={{
                                        endAdornment: <InputAdornment position="end"><Typography>%</Typography></InputAdornment>
                                    }}
                                />
                            </Grid>
                        </Grid>
                    ))}
                </Container>
            </DialogContent>
            <DialogActions>
                <Grid container spacing={12} justify='space-evenly' >
                    <Grid item xs={5} justify='center'>
                        <FantasyButton label='Cancel' variant='outlined' fullWidth onClick={onCancel} />
                    </Grid>
                    <Grid item xs={5} >
                        <FantasyButton label='Okay' variant='contained' onClick={onSubmit} fullWidth />
                    </Grid>
                </Grid>
            </DialogActions>
        </Dialog>
    )
}
export default FlexExposureDialog;