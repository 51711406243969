import axios from 'axios';

export const getLocalToken = async () => {
  const data = localStorage.getItem('token');
  return data;
};

export const isLoggedIn = () => {
  const isLogin = localStorage.getItem('userDetails');
  const user = JSON.parse(isLogin);
  return user;
};

export const onLogout = async () => {
  await localStorage.clear();
  window.location.href = '/login';
};

export const setAuthToken = async () => {
  const token = await getLocalToken();
  if (token) axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  else delete axios.defaults.headers.common.Authorization;
};



/**
 * @returns {{subscribe:function, unsubscribe:function, notify:function}}
 */
function authWatcher(){
  const listeners = new Set();
  /**
   * Subscribe to authentication events.
   * @param {function} listener
   */
  function subscribe(listener){
      listeners.add(listener);
  }


  /**
   * Ubsubscribe from the authentication events.
   * @param {function} listener
   */
  function unsubscribe(listener){
      listeners.delete(listener);
  }


  /**
   * Notify the listeners about authentication event.
   * @param {any} value
   */
  function notify(value){
    const{email, token} = value;
    localStorage.setItem('token',token);
    localStorage.setItem('userDetails',JSON.stringify({email}));

    listeners.forEach(listener => listener(value.email));
  }


  return {
    subscribe, unsubscribe, notify
  }
}


export const AuthWatcher = new authWatcher();
