import React from "react";
import PropTypes from "prop-types";
import "./style.css";
import { Link } from "react-router-dom";

/**
 * Navigation tab
 * @param {Object} props
 * @param {String} props.linkTo Href for the navtab
 * @param {number} props.active Number of the active link
 * @returns {React.ReactNode}
 */
function DrawerLink(props) {
  const { linkTo, active, label, ...rest } = props;
  return (
    <Link {...rest}
      className={'drawer-link ' +  (active ? "drawer-link-active" : "drawer-link-default")}
      to={linkTo}
    >
      {label}
    </Link>
  );
}

DrawerLink.propTypes = {
  linkTo: PropTypes.string.isRequired,
  active: PropTypes.bool.isRequired,
  label: PropTypes.string.isRequired
};

export default DrawerLink;
