import React, { useEffect } from 'react';
import BaseCard from '../../components/baseCard';
import { TableContainer, Table, TableBody } from '@material-ui/core';

import { useState } from 'react';
import PropTypes from 'prop-types';
import GameRow from './gameTableRow';
import { useCallback } from 'react';
import FantasyTableHeader from '../../components/fantasyTableHeader';

GamesListView.propTypes = {
    games: PropTypes.arrayOf(PropTypes.shape({
        id:             PropTypes.number.isRequired,
        homeTeam:       PropTypes.string.isRequired,
        awayTeam:       PropTypes.string.isRequired,
        homeTeamScore:  PropTypes.number.isRequired,
        awayTeamScore:  PropTypes.number.isRequired,
        overUnder:      PropTypes.number.isRequired,
        line:           PropTypes.string.isRequired
    })),
    excludeTeams:       PropTypes.object.isRequired,
    onToggleTeam:       PropTypes.func.isRequired

}

const tooltip = `Don't like a particular team this week? Uncheck them and they won't show up in your lineups.`;

const headers = [
    { key: "homeCheckBox",  label:'',            isSortable:false, width:'2rem' },
    { key: "homeTeam",      label: "Home",       isSortable: true           },
    { key: "homeTeamScore", label: "Proj Score", isSortable: true           },
    { key: "awayCheckBox",  label:'',            isSortable:false, width:'2rem' },
    { key: "awayTeam",      label: "Away",       isSortable: true           },
    { key: "awayTeamScore", label: "Proj Score", isSortable: true           },
    { key: "overUnder",     label: "O/U",        isSortable: true           },
    { key: "line",          label: "Line",       isSortable: true           },
    { key: "gameDate",      label: "Date",       isSortable: true           },
    { key: "time",          label: "Time",       isSortable: true           },
];

function GamesListView({games, slate, excludeTeams, isLoading, onToggleTeam}) {

    const [sort, setSort] = useState({sortBy:'gameDate', order:'asc'});
    const [sortedGames, setSortedGames]=useState(games)

    useEffect(()=>{
        const {sortBy, order} = sort;
        const multiplier = order === 'asc' ? 1:-1;
        //const filteredGames = slate === 'All' ? games:games.filter(game=>game[slate])
        setSortedGames(games.slice(0).sort((a,b)=> (a[sortBy] < b[sortBy]?-1: (a[sortBy] === b[sortBy] ? 0:1))*multiplier));
    },[games, sort, slate, setSortedGames])


    const onSortHandler = useCallback(key=>{
        setSort(({sortBy, order}) => ({sortBy:key, order:(key !== sortBy ? 'asc' : (order === 'asc' ? 'desc' : 'asc'))}))
    },[])

    return (
        <BaseCard title='NFL Games' tooltip={tooltip}>
            <TableContainer>
                <Table size='small'>
                    <FantasyTableHeader
                        headers={headers}
                        sortBy={sort.sortBy}
                        sortOrder={sort.order}
                        onSort={onSortHandler}
                    />

                {/* Table Body */}
                <TableBody>
                    {sortedGames.map(
                        game => {
                            return (
                                <GameRow
                                   key={game.id}
                                   homeTeam={game.homeTeam}
                                   awayTeam={game.awayTeam}
                                   homeTeamScore={game.homeTeamScore}
                                   awayTeamScore={game.awayTeamScore}
                                   overUnder={game.overUnder.toFixed(1)}
                                   line={game.line}
                                   matchDate={game.date}
                                   matchTime={game.time}
                                   isHomeChecked={!!excludeTeams[game.homeTeam] === false}
                                   isAwayChecked={!!excludeTeams[game.awayTeam] === false}
                                   onToggleTeam={onToggleTeam}
                                />
                            );
                        }
                    )}
                </TableBody>
                </Table>
            </TableContainer>
        </BaseCard>
    )
}

export default React.memo(GamesListView);