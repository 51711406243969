import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
    TextField, makeStyles, Grid, Typography, InputAdornment
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Autocomplete from '@material-ui/lab/Autocomplete';
import DeleteIcon from '@material-ui/icons/Delete';
import IconButton from '@material-ui/core/IconButton';

const Accordion = withStyles({
    root: {
        border: '1px solid rgba(0, 0, 0, .125)',
        boxShadow: 'none',
        '&:not(:last-child)': {
            borderBottom: 0,
        },
        '&:before': {
            display: 'none',
        },
        '&$expanded': {
            margin: 'auto',
        },

    },
    expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
    root: {
        //   backgroundColor: 'rgba(0, 0, 0, .03)',

        borderBottom: '1px solid rgba(0, 0, 0, .125)',
        marginBottom: -1,
        minHeight: 36,
        '&$expanded': {
            background: '#f5d6d6',
            minHeight: 36,
        },
        paddingRight: 48
    },
    content: {
        '&$expanded': {
            margin: '12px 0',
        },
    },
    expanded: {},
})(MuiAccordionSummary);

const useStyles = makeStyles(theme => ({
    stackTitleRow: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%'
    },
    deleteButton: {
        position: 'absolute',
        right: 16,
        height: 30,
        marginRight: -6,
        marginTop: 'auto',
        marginBttom: 'auto'
    }
}));


const Stack = ({ index, expanded, onToggleStack, weight, keyPlayer, onKeyPlayerChange,
    onWeightChanged, included, excluded, onIncludePlayer, onExcludePlayer, players, onDelete, ...rest }) => {
    const classes = useStyles();
    const [unavailablePlayers, setUnavailablePlayers] = useState({})

    const isDisabled = option => {
        const isDisabled = unavailablePlayers[option.id];
        return isDisabled;
    }

    useEffect(() => {
        const result = {};
        [keyPlayer || {}].concat(included, excluded)
            .forEach(player => result[player.id] = true);
        setUnavailablePlayers(result);
    }, [setUnavailablePlayers, keyPlayer, included, excluded]);

    const validateWeight = useEffect((e) => {
        if (!e) return;
        const value = +e.target.value || 0;
        onWeightChanged(value)
    }, [onWeightChanged])

    //To handle sorting of player drop down list initially
    const sortedPlayers=useMemo(()=>{
        let tempPlayers=players.slice(0);
        return(tempPlayers.sort((player1,player2)=>{
            if(player1.team.localeCompare(player2.team)!==0)
            {
                return(player1.team.localeCompare(player2.team));
            }
            else if(player1.position.localeCompare(player2.position)!==0)
            {
                return(player1.position.localeCompare(player2.position));
            }
            else
            {
                return(player1.points-player2.points);
            }
        }));
    },[players]);

    //function for filtering drop down list
    const filterDropDown=(players,state)=>{
        return(players.filter( player => {
            const filterChecker= value => value.toLowerCase().includes(state.inputValue.toLowerCase());
            return(filterChecker(player.name) || filterChecker(player.team) || filterChecker(player.position));
        }));
    }

    return (
        <Accordion expanded={expanded === index} onChange={onToggleStack}>

            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1bh-content"
                id={`stack-${index}-header`}
                classes={{
                    content: classes.stackTitleRow
                }}
            >
                <IconButton
                    size='small'
                    onClick={onDelete}
                    className={classes.deleteButton}
                    onFocus={(event) => event.stopPropagation()}
                >
                    <DeleteIcon />
                </IconButton>
                <Typography className={classes.heading}>Stack {index + 1}</Typography>
                <TextField
                    type="number"
                    name='weight'
                    style={{ width: '6rem' }}
                    placeholder='Weight'
                    value={weight}
                    onBlur={validateWeight}
                    onChange={onWeightChanged}
                    onClick={(event) => event.stopPropagation()}
                    onFocus={(event) => event.stopPropagation()}
                    InputProps={{
                        endAdornment: <InputAdornment position="end"><Typography>%</Typography></InputAdornment>
                    }}
                />
            </AccordionSummary>
            <MuiAccordionDetails>
                <Grid container alignItems='center' spacing={2}>
                    <Grid item xs={12} md={2}>
                        <Typography>Stack</Typography>
                    </Grid>
                    <Grid item xs={12} md={10}>
                        <Autocomplete
                            id="key-player"
                            fullWidth
                            options={sortedPlayers}
                            classes={{
                                option: classes.option,
                            }}
                            defaultValue={keyPlayer}
                            size='small'
                            onChange={onKeyPlayerChange}
                            autoHighlight
                            filterOptions={filterDropDown}
                            getOptionLabel={(option) => {
                                return option?.name || ''
                            }}
                            renderOption={(player) => (
                                <React.Fragment>
                                    {player.name} - {player.team} - {player.position}
                                </React.Fragment>
                            )}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Choose a player"
                                    variant="outlined"
                                    size='small'
                                    inputProps={{
                                        ...params.inputProps,
                                        autoComplete: 'new-password', // disable autocomplete and autofill
                                    }}
                                />
                            )}
                        />
                    </Grid>

                    <Grid item xs={12} md={2}>
                        <Typography>With</Typography>
                    </Grid>
                    <Grid item xs={12} md={10}>
                        <Autocomplete
                            id="key-player"
                            fullWidth
                            disabled={!keyPlayer}
                            options={sortedPlayers}
                            onChange={onIncludePlayer}
                            defaultValue={included}
                            classes={{
                                option: classes.option,
                            }}
                            size='small'
                            autoHighlight
                            getOptionDisabled={isDisabled}
                            filterOptions={filterDropDown}
                            getOptionLabel={(option) => {
                                return option?.name || ''
                            }}
                            renderOption={(player) => (
                                <React.Fragment>
                                    {player.name} - {player.team} - {player.position}
                                </React.Fragment>
                            )}
                            multiple
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Select players"
                                    variant="outlined"
                                    size='small'
                                    inputProps={{
                                        ...params.inputProps,
                                        autoComplete: 'new-password', // disable autocomplete and autofill
                                    }}
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={12} md={2}>
                        <Typography>Without</Typography>
                    </Grid>
                    <Grid item xs={12} md={10}>
                        <Autocomplete
                            id="excluded-player"
                            fullWidth
                            disabled={!keyPlayer}
                            options={sortedPlayers}
                            onChange={onExcludePlayer}
                            defaultValue={excluded}
                            classes={{
                                option: classes.option,
                            }}
                            size='small'
                            autoHighlight
                            getOptionDisabled={isDisabled}
                            filterOptions={filterDropDown}
                            getOptionLabel={(option) => {
                                return option?.name || ''
                            }}
                            renderOption={(player) => (
                                <React.Fragment>
                                    {player.name} - {player.team} - {player.position}
                                </React.Fragment>
                            )}
                            multiple
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Select players"
                                    variant="outlined"
                                    size='small'
                                    inputProps={{
                                        ...params.inputProps,
                                        autoComplete: 'new-password', // disable autocomplete and autofill
                                    }}
                                />
                            )}
                        />
                    </Grid>

                </Grid>

            </MuiAccordionDetails>
        </Accordion>
    )
}

export default Stack;