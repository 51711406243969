import React from 'react';
import Helmet from '../../assets/img/nfl_helmet.svg'
import PropTypes from 'prop-types';
import {Fade, makeStyles } from '@material-ui/core';
import { FantasyButton } from '../../components/fatnasyButton';

const useStyles = makeStyles(theme=>({
    center :{
        position:'absolute',
        display:'flex',
        flexDirection:'column',
        justifyContent:'center',
        alignItems:'center',
        width:'100%',
        height:'calc(100% - 72px)'
    },
    image: {
        width:'200px',
        maxWidth:'50%',
        opacity:'15%',
    },
    actionButton: {
        marginTop: theme.spacing(2)
    }
}));

NoHistory.propTypes = {
    message: PropTypes.string,
    onClick: PropTypes.func
}


function NoHistory({message, onClick, ...rest}){
    const classes = useStyles();

    return  <Fade in={true}  timeout={1000} >
    <div className={classes.center}>

        <img
          src={Helmet}
          alt="Empty team"
          className={classes.image}
          onClick={() => {}}/>
          <FantasyButton label='No Teams - Create Now' variant='outlined' className={classes.actionButton} />
    </div>
    </Fade>

}

export default NoHistory;