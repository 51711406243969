// This method is used to restrict dot(.), plus(+),minus(-) and e
export const onlyNumbers = (event) => {
  return [69, 187, 189, 190].includes(event.keyCode) && event.preventDefault();
};

export const sortByPosition = (array) => {
  let QB = [];
  array.forEach((data) => {

      QB.push(data);

  });
  QB = QB.sort((a, b) => (a.position - b.position));

  let result = [...QB];
  return result;
};

export const sortByRound = (array) => {
  let QB = [];
  array.forEach((data) => {

      QB.push(data);

  });
  QB = QB.sort((a, b) => (a.round_id - b.round_id));

  let result = [...QB];
  return result;
};


export function downloadCSV(lineups, site, filename='lineup.csv', isCaptainMode = false) {
  const text = getCSVString(lineups,site, isCaptainMode);
  window.text = text;
  var element = document.createElement('a');
  element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(text));
  element.setAttribute('download', filename);

  element.style.display = 'none';
  document.body.appendChild(element);

  element.click();

  document.body.removeChild(element);
}


function getCSVString(lineups, site, isCaptainMode) {
  let csv = [];


  if (site === "DraftKings") {
    if(isCaptainMode){
      csv.push(["CPT", "Flex", "Flex", "Flex", "Flex", "Flex"]);
    } else {
      csv.push(["QB", "RB", "RB", "WR", "WR", "WR", "TE", "FLEX", "DST"].join(','));
    }


    lineups.forEach(lineup => {
      const values = lineup.players.map(player => `"${player.name} (${player.siteId})"`).join(',');
      csv.push(values);
    });
  } else if (site === "Fanduel") {
    if(isCaptainMode){
      csv.push(["MVP - 1.5X Points", "AnyFLEX", "AnyFLEX", "AnyFLEX", "AnyFLEX"]);
    } else {
      csv.push(["QB", "RB", "RB", "WR", "WR", "WR", "TE", "FLEX", "DEF"]);
    }


    lineups.forEach(lineup => {
      const values = lineup.players.map(player => `"${player.siteId}:${player.name}"`).join(',');
      csv.push(values);
    })
  }
  return csv.join('\n');

}