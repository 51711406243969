import { withStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';

const FantasyTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: 'rgba(0,0,0,0.9)',
    color: theme.palette.common.white,
    boxShadow: theme.shadows[2],
    fontSize: '1rem',
    fontWeight:400
  },
}))(Tooltip);

export default FantasyTooltip;