import types from "../actions/action-types";
import dayjs from "dayjs";
import timezone from 'dayjs/plugin/timezone';
var utc = require('dayjs/plugin/utc')

dayjs.extend(timezone);
dayjs.extend(utc);

const DEFAULT_STATE ={
  isError: false,
  isLoading: false,
  query: {},
  res:[],
  isSuccess: false
};
export default (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case `${types.GAMES}_LOADING`:
      return {
        isError: false,
        isLoading: true,
        query: {...action.updatePayload},
        res:[]
      };
    case `${types.GAMES}_REJECTED`:
      return {
        ...state,
        isLoading:false,
        errorMessage: action.updatePayload.res,
        isError: true
      };
    case `${types.GAMES}_SUCCESS`:
      const games = action.updatePayload.res;
      games.forEach(game=>{
        const parsedDate = dayjs(game.gameDate);
        game.date = parsedDate.utc().format('MM/DD/YYYY');
        game.time = parsedDate.utc().format('hh:mm A');
        game.gameDate = parsedDate.unix();
      })
      return {
        ...state,
        isSuccess: true,
        isLoading:false,
        res: action.updatePayload.res,
        isError:false
      };

    default:
      return state;
  }
};
