import React from 'react';
import { AuthContext } from '../../firebase/Auth';
import { Route, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';

const PrivateRoute = ({ component: RouteComponent, ...rest }) => {
    const { currentUser } = React.useContext(AuthContext);
    return (
      <Route {...rest}
        render={(routeProps) => !!currentUser ? (
            <RouteComponent {...routeProps} />
          ) : (
            <Redirect to={"/login"} />
          )
        }
      />
    );
  };

  PrivateRoute.propTypes = {
      component: PropTypes.oneOfType([PropTypes.func, PropTypes.node])
  }

  export default PrivateRoute;