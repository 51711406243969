import React from "react";
import {
  TableCell,
  TableRow,
  Checkbox, makeStyles,
  colors
} from "@material-ui/core";
import PropTypes from 'prop-types';

GameRow.propTypes = {
  homeTeam: PropTypes.string.isRequired,
  awayTeam: PropTypes.string.isRequired,
  homeTeamScore: PropTypes.number.isRequired,
  awayTeamScore: PropTypes.number.isRequired,
  overUnder: PropTypes.number.isRequired,
  line: PropTypes.string.isRequired,
  isHomeChecked: PropTypes.bool.isRequired,
  isAwayChecked: PropTypes.bool.isRequired
}

const useStyles = makeStyles(theme=>({
  disabled: {
    backgroundColor: colors.grey[300],
    opacity:'70%'
  }
}))

function GameRow({ id, disabled, homeTeam, awayTeam, homeTeamScore, awayTeamScore, overUnder, line, isHomeChecked, isAwayChecked, matchDate, matchTime, onToggleTeam }) {
  const classes = useStyles();
  function toggleCheckbox(e) {
    onToggleTeam(e.target.name);
  }

  return (
    <TableRow hover={!disabled} key={id} className={disabled ? classes.disabled:''}>
      <TableCell style={{paddingTop:0, paddingBottom:0}}>
        {/* Home Team */}
      <Checkbox
        disabled={disabled}
        size='small'
        color="primary"
        name={homeTeam}
        checked={isHomeChecked && !disabled}
        onChange={toggleCheckbox}
      />
      </TableCell>
      <TableCell align="center" className={classes.bodyCell}>
          {homeTeam}
      </TableCell>

      {/* Home Projected Score */}
      <TableCell align="center" className={classes.bodyCell}>
        {homeTeamScore}
      </TableCell>

      {/* Away */}
      <TableCell style={{paddingTop:0, paddingBottom:0}}>
            <Checkbox
              disabled={disabled}
              color="primary"
              size='small'
              name={awayTeam}
              checked={isAwayChecked && !disabled}
              onChange={toggleCheckbox}
            />
      </TableCell>
      <TableCell>
          {awayTeam}
      </TableCell>

      {/* Away Projected Score */}
      <TableCell align="center" className={classes.bodyCell}>
        {awayTeamScore}
      </TableCell>

      {/* OU */}
      <TableCell align="center" className={classes.bodyCell}>
        {overUnder}
      </TableCell>

      {/* Line */}
      <TableCell align="center" className={classes.bodyCell}>
        {line}
      </TableCell>

      {/* Date */}
      <TableCell align="center" className={classes.bodyCell}>
        {matchDate}
      </TableCell>

      {/* TIme */}
      <TableCell align="center" className={classes.bodyCell}>
        {matchTime}
      </TableCell>
    </TableRow>
  );
}


export default GameRow;