import { useState, useCallback } from "react";


function useSorting(defaultKey, defaultOrder){
    const [sort, setSort] = useState({sortBy:defaultKey, order:defaultOrder});

    const onSort = useCallback(key=>{
        setSort(({sortBy, order}) => ({sortBy:key, order:(key !== sortBy ? 'asc' : (order === 'asc' ? 'desc' : 'asc'))}))
    },[setSort])
    return [sort, onSort]
}

export default useSorting;