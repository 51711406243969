import React, { Component } from "react";
import { Router, Route, Switch, Redirect } from "react-router-dom";
import { createBrowserHistory } from "history";
import Login from "./containers/login";
import { isLoggedIn, setAuthToken } from "./services/auth";

import { AuthProvider } from "./firebase/Auth";
import NavBarRoot from "./containers/NavBarRoot";
import PrivateRoute from "./components/privateRoute";

const history = createBrowserHistory();


export default class Routes extends Component {
  async UNSAFE_componentWillMount() {
    const user = await isLoggedIn();
    if (user) {
      setAuthToken();
      this.setState({ user });
    } else {
      history.push("/login");
    }
  }

  render() {
    return (
      <Router history={history}>
        <Switch>
          <AuthProvider>
            <Route exact path='/'>
              <Redirect to='/login'/>
            </Route>
            <Route path="/login" component={Login} />
            <PrivateRoute path="/optimizer" component={NavBarRoot} />
          </AuthProvider>
        </Switch>
      </Router>
    );
  }
}
