import { createMuiTheme } from '@material-ui/core';


const theme = createMuiTheme({
    palette: {
        primary : {
            main: '#c33'
        }
    },
    spacing:8
})

export default theme;