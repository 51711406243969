import React from 'react';
import NavBar from '../../components/navBar/navBar';
import { useRouteMatch, Switch, Route, Redirect } from 'react-router-dom';
import LineupSetup from '../lineupSetup';
import AllLineups from '../allLineups';
import AdvanceSettings from '../advanceSettings';
import Footer from '../../components/footer/footer';



function NavBarRoot({children, ...rest}){
    const {path, url} = useRouteMatch();
    return <>

        <NavBar {...rest}/>
        <Switch>
            <Route exact path={path}>
                <Redirect to={`${url}/setup`} />
            </Route>
            <Route path={`${url}/setup`} component={LineupSetup} />
            <Route path={`${url}/player-settings`} component={AdvanceSettings} />
            <Route path={`${url}/all-teams`} component={AllLineups} />
        </Switch>
        <Footer />
    </>
}


export default NavBarRoot;