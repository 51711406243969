import React from 'react';
import {Button, makeStyles} from '@material-ui/core';
import PropTypes from 'prop-types';


const useStyles = makeStyles((theme) => ({
    fantasyButton:{
        borderRadius:50
    }
}));

/**
 *
 * @param {{label:string, variant:'contained'|'outlined',[string]:* }} props
 */
export function FantasyButton(props){
    const classes = useStyles();
    const {label, variant, className, ...others} = props;
    return (
        <Button
            className={classes.fantasyButton + ' ' + className}
            variant={props.variant || 'contained'}
            color='primary'
            size='medium'
            {...others}
            >
                {label}
        </Button>
    )
}

FantasyButton.propTypes={
    variant: PropTypes.oneOf(['contained','outlined']),
    label: PropTypes.string,
    onClick: PropTypes.func
}

export default React.memo(FantasyButton);