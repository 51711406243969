import React, { useEffect, useState } from "react";
import { createBrowserHistory } from "history";
import { AuthWatcher, isLoggedIn } from "../services/auth";


const history = createBrowserHistory();

export const AuthContext = React.createContext();

export const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(isLoggedIn());

  useEffect(() => {
    AuthWatcher.subscribe(setCurrentUser);
    return function unsubscribe(){
      AuthWatcher.unsubscribe(setCurrentUser);
    }
  }, [setCurrentUser]);

  return (
    <AuthContext.Provider
      value={{
        currentUser,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
