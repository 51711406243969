
const headers = [
    { key: 'position',      label: 'Position',      	isSortable: true, align:'center'	},
    { key: 'name',          label: 'Name',          	isSortable: true, align:'left' 	, width:'12rem'},
    { key: 'arrowDown',     label:'Bump Down',     isSortable: false, align:'center', },
    { key: 'arrowUp',       label:'Bump Up',       isSortable: false, align:'center', tooltip: `Each "bump up" or "bump down" increases/reduces projections by 10%.`},
    { key: 'team',          label: 'Team',          	isSortable: true, align:'center' 	},
    { key: 'date',          label: 'Game Time',     	isSortable: true, align:'left' 	},
    { key: 'opponent',      label: 'Opponent',      	isSortable: true, align:'center' 	},
    { key: 'projection',    label: 'Proj. Points',      isSortable: true, align:'center' 	},
    {key: 'customPoints',   label: 'Custom Points',     isSortable: false, align:'center'},
    { key: 'salary',        label: 'Salary',          	isSortable: true, align:'center' 	},
//    { key: 'matchup',       label: 'Matchup',       	isSortable: true, align:'center' 	 },
    { key: 'value',         label: 'Value',         	isSortable: true, align:'center' 	},
    { key: 'ownership',     label: 'Ownership',     	isSortable: true, align:'center' 	},
    { key: 'minExposure',   label: ['Exposure', 'Min %'],  	isSortable:true,   multiline: true 	},
    { key: 'maxExposure',   label: ['Exposure', 'Max %'],  	isSortable:true,   multiline: true 	},
    { key: 'overrides',     label: 'Lock/Exclude',  	isSortable: false 	},
];

export default headers;