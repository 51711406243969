export default {
  // Auth based actions
  LOGIN: "LOGIN",

  // Get all matches
  GAMES: 'GAMES',
  PLAYERS: 'PLAYERS',
  OPTIMIZE: 'OPTIMIZE',
  FORM: 'FORM',
  SLATES: 'SLATES'
};
