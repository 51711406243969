import React, { useState, useEffect, useMemo } from 'react';
import { AutoSizer } from 'react-virtualized';
import { useSnackbar } from "notistack";
import BaseCard from '../../components/baseCard';
import { Grid, makeStyles, List, ListItem, ListItemText, Divider, CircularProgress, Fab, Fade} from '@material-ui/core';
import { FantasyButton } from '../../components/fatnasyButton';
import { connect } from 'react-redux';
import { useCallback } from 'react';
import DownloadIcon from '@material-ui/icons/CloudDownloadRounded';
import { downloadCSV } from '../../helpers/helpers';
import useGoogleAnalytics from '../../hooks/useGoogleAnalytics';
import Timer from './timer';
import { getLastOptimizedTeam } from '../../redux/actions/optimizer.action';
import { bindActionCreators } from 'redux';
import ErrorDialog from './errorDialog';
import NoHistory from './noHistory';
import usePermissions from "../../hooks/usePermissions";
import MembershipTierDialog from '../../components/membershipTierDialog/membershipTierDialog';

const useStyles = makeStyles(theme => ({
    teamCard: {
        width: '18.75rem',
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(2),
        flexShrink: 0,
        flexGrow: 0,
        height:'fit-content',
        textAlign:'center'
    },
    teamCardTitle: {
        justifyContent: 'center'
    },
    listText: {
        textAlign:'center'
    },
    centerText: {
        textAlign:'center',
        width:'100%',
        position:'absolute',
        top:'45%',
        height:'100%'
    },
    loader: {
        position:'absolute',
        top:'45%',
        textAlign:'center',
        left: 0,
        right: 0, 
        marginLeft: 'auto',
        marginRight: 'auto'
    }
}))


const allHeaders = {
    classic   : ['Projected Points', 'Salary', 'QB', 'RB1', 'RB2', 'WR1', 'WR2', 'WR3', 'TE', 'Flex', 'DST'],
    fanduel   : ['Projected Points', 'Salary', 'MVP', 'Flex', 'Flex', 'Flex', 'Flex'],
    draftkings: ['Projected Points', 'Salary', 'CPT', 'Flex', 'Flex', 'Flex', 'Flex', 'Flex'],
    yahoo   : ['Projected Points', 'Salary', 'Superstar', 'Flex', 'Flex', 'Flex', 'Flex'],
}

const Headers = ({ label, className }) => (
    <>
        <ListItem dense>
            <ListItemText primary={label} className={className} />
        </ListItem>
        <Divider />
    </>
)


const Item = ({ label, className }) => (
    <>
        <ListItem dense >
            <ListItemText primary={label} className={className} />
        </ListItem>
        <Divider />
    </>
)
const NUMBER_REGEX = /\B(?=(\d{3})+\b)/g;


function AllLineups({ options, error, allTeams, playerCounts, isLoading, isError,history, isWaiting, isCaptainMode, timeRemaining,getLastOptimizedTeam, ...props }) {
    const classes = useStyles();
    const [sort, setSort] = useState('Points');
    const [teams,setTeams] = useState([]);
    const {reportDownloads} = useGoogleAnalytics('/optimizer/all-teams');
    const { enqueueSnackbar } = useSnackbar();
    const permissions = usePermissions();
    const [isMembershipTierOpen, setIsMembershipTierOpen] = useState(false);
    const [membershipTierMsg, setMembershipTierMsg] = useState();
    const toggleSort = useCallback(()=>{
        setSort(lastSort=> lastSort ==='Points'?'QB':'Points')
    },[setSort]);

    useEffect(()=>{
        if(!allTeams) return;
        if(sort === 'Points'){
            setTeams(allTeams.sort((a,b)=> b.total-a.total));
        } else {
            setTeams(allTeams.sort((a,b)=> b.players[0].projection-a.players[0].projection));
        }
    },[allTeams, sort])

    const headers = useMemo(()=>{
        if(!isCaptainMode) return allHeaders.classic;
        if(options.site === 'Fanduel') return allHeaders.fanduel;
        if(options.site === 'Yahoo') return allHeaders.yahoo;
        return allHeaders.draftkings;
    },[options.site, isCaptainMode])

    const toggleMembershipDialog = useCallback((msg)=>{
        setIsMembershipTierOpen(open => !open);
        setMembershipTierMsg(typeof(msg) === 'string' ? msg:'');
      },[setIsMembershipTierOpen, setMembershipTierMsg])    

    const handleDownload = useCallback(()=>{
        if(permissions?.csv) {
            if(!teams || !teams.length) return;
            reportDownloads(options.site, teams.length);
            downloadCSV(teams,options.site,undefined, isCaptainMode)
        } else {
            toggleMembershipDialog("Download CSV is a premium feature.")
        }
    },[teams,options.site, reportDownloads, toggleMembershipDialog])



    useEffect(()=>{
        if(props.noHistory === undefined && !isLoading && !error){
            getLastOptimizedTeam();
        }

        if(error || props.res?.warning){
            enqueueSnackbar(error || 'Cannot find all requested lineups with current constraints.',{
                variant: 'info',
                anchorOrigin: {
                    horizontal: 'right',
                    vertical: 'bottom'
                },
                preventDuplicate:true
            });
        }
    },[props.noHistory, isLoading, props.res, enqueueSnackbar, error, getLastOptimizedTeam])



    const onClickFix = useCallback(()=>{
        history.push('/optimizer/player-settings');
    },[history])


    const onClickSetup = useCallback(()=>{
        history.push('/optimizer/setup')
    },[history]);

    return (
        <>
            <MembershipTierDialog
            title="Premium Subscription Required"
            message={membershipTierMsg}
            isOpen={isMembershipTierOpen}
            onCancel={toggleMembershipDialog}  />

        {error && <ErrorDialog error={error} onClickFix={onClickFix} />}
        <AutoSizer>
            {({ height, width }) => {

                if (props.noHistory)
                    return <NoHistory message="You have no history" onClick={onClickSetup}  />

                if ((isLoading || isWaiting || !allTeams)  && !error)
                    return <div className={classes.loader} >
                        <CircularProgress size={150} />
                        <div className={classes.centerText}>
                            {isWaiting && <Timer countodwnTime={timeRemaining} onCountdownFinished={getLastOptimizedTeam}  />}
                            {!isWaiting && 'Please wait...'}
                        </div>
                        <div>{isWaiting && options.lineups>20 && 'When generating Lineups, note that complicated setups or busy times may take 3-4 times as long as the estimated time shown above.'}</div>
                    </div>

                return (
                <Grid container style={{ margin: 16, height:500, width: width - 32 }} spacing={2}>
                    <Grid item xs={6} sm={4} md={2}>
                        <FantasyButton label={'Sort by ' + sort} variant='contained' size='large' fullWidth style={{ height: 45 }} onClick={toggleSort}/>
                        <List>
                            {
                                headers.map(header => <Headers key={header} label={header} className={classes.listText}/>)
                            }
                        </List>
                    </Grid>
                    <Grid container item xs={6} sm={8} md={10} style={{ overflowX: 'auto',  paddingLeft:'1rem' }} direction='row' wrap='nowrap'  >
                        { !isLoading &&
                            teams.map((lineup,index) => (
                                <Fade in={true} style={{transitionDelay: index < 10 ? index*150:0}} key={index}>
                                <BaseCard title={`Team ${index+1}`} baseClass={classes.teamCard} titleClass={classes.teamCardTitle} >
                                    <List>
                                        <Item label={lineup.total.toFixed(1)} className={classes.listText}/>
                                        <Item label={'$' + lineup.salary.toString().replace(NUMBER_REGEX,',')} className={classes.listText}/>
                                        {
                                                lineup.players.map(player=><Item key={player.id} label={`${player.name} (${player.projection.toFixed(1)} - ${(playerCounts[player.id] / teams.length * 100).toFixed(1) + "%"})`} className={classes.listText}/>)
                                        }
                                    </List>
                                </BaseCard>
                                </Fade>
                            ))
                        }

                    </Grid>
                    {options.site!=='Yahoo' &&
                    <Fab
                        color='primary'
                        style={{position:'absolute', right:32, bottom:32}}
                        onClick={handleDownload}
                    >
                    <DownloadIcon />
                </Fab>
            }
                </Grid>

            )}
            }
        </AutoSizer>

        </>
    )
}






const mapStateToProps = (state) => {
    return state.Optimize;
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({ getLastOptimizedTeam}, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(AllLineups);